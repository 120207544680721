import React from "react";
import Section1 from "../elements/homeSections/Section1";
import Section2 from "../elements/homeSections/Section2";
import Section3 from "../elements/homeSections/Section3";
import Section4 from "../elements/homeSections/Section4";
import Section5 from "../elements/homeSections/Section5";
import Section6 from "../elements/homeSections/Section6";
import Section7 from "../elements/homeSections/Section7";
import info from '../../inrees-landing/image/picto/info.png';
import SEOMixins from "../mixins/SEOMixins";

const TiketDor = () => {

return (
      
        <main role="main">
            <SEOMixins title="Ticket d'or - Inexploré" description="Participez au jeu-concours extraordinaire que nous avons imaginé : nous glisserons 1 ticket d 'or, 10 tickets d 'argent, et 20 tickets de bronze dans les numéros destinés à nos abonnés." />
            <div class="path-frontpage">
                <div class="container">
                    <Section1/>
                    <Section2/>
                    <Section3/>
                    <Section4/>
                    <Section5/>
                    <Section6/>
                    <Section7/>
                    <section class="cgv">
                    <div class="max-width">
                        <img src={info} alt="" />
                        <p><a href="https://www.inrees.com//conditions-generales-vente" class="underline">Consultez les CGV et réglement du concours</a>.</p>
                    </div>
                </section>    
                </div>
            </div>
        </main>  
   
)
}

export default TiketDor