import React from "react";
import cover from '../../../inrees-landing/image/cover60.jpg';

const Section2 = () => {


    return(
        <section className="special-edition">
            <div className="max-width">
                <div className="img">
                    <img src={cover} alt="" />
                </div>
                <div className="text">
                    <h2>Ouvrez l'œil, cet Inexploré sera <span>spécial…</span></h2>
                    <p>
                        Cet automne, Inexploré magazine fête son 60<sup>ème</sup> numéro. Quinze ans d'existence ! Une sacrée histoire
                       pour la rédaction et la communauté de lecteurs.<br /><br />Fêtez cet anniversaire avec nous !<br /><br />Participez
                       au jeu-concours extraordinaire que nous avons imaginé : nous glisserons 1 ticket d 'or, 10 tickets d 'argent, et 
                       20 tickets de bronze dans les numéros destinés à nos abonnés. <br /><br /><strong>Envie de participer ?</strong>
                    </p>
                </div>
            </div>
        </section>
                  
    )
}

export default Section2