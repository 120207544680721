import React from "react";
import tirage from '../../../inrees-landing/image/tirage-v2.png';
import Btn from "../BtnStyle";

const Section5 = () => {



    return(
        <section className="participate">
                    <div className="max-width">
                        <div className="img">
                            <img src={tirage} alt="" />
                        </div>
                        <div className="text">
                            <h2>Comment participer ?</h2>
                             <p>
                                Pour participer, il n'y a qu'une condition à respecter : être abonné à Inexploré magazine + une autre 
                                formule (Inexploré digital ou  TV). Et c'est tout !<br /><br />Le tirage au sort parmi les abonnés se 
                                déroulera en septembre.<br /><br />Les gagnantes et gagnants recevront un ticket glissé dans leur numéro
                                 d'automne, Inexploré n°60.<br /><br /><br /><br />
                             </p>
                             <strong>Si vous n'êtes pas abonné…</strong>
                             <p>
                               Vous avez jusqu 'au 31 août pour <a href="https://abonnements.inexplore.com/je-mabonne?selectedAbos=[3]" target="_blank" class="underline">souscrire à Inexploré magazine</a> + une autre formule de votre choix.<br /><br />
                             </p>
                            <Btn link='https://abonnements.inexplore.com/je-mabonne' customBtn="btn-sec first-button">
                             Je m'abonne
                            </Btn>                              
                            <strong>Si vous avez souscrit à un seul abonnement et/ou vous n'êtes pas abonné à Inexploré magazine...</strong>
                            <p>
                                Jusqu'au 31 août, vous pouvez ajouter une nouvelle formule de votre choix depuis 
                                <a href="https://my.inexplore.com/abonnement" target="_blank" class="underline">votre espace personnel</a>, en 
                                quelques clics.
                            </p>
                            <Btn link='https://abonnements.inexplore.com/je-mabonne' customBtn="btn-sec">
                             Je transforme mon abonnement
                            </Btn>  
                        </div>
                    </div>
                </section> 
                  
    )
}

export default Section5