import React from "react";
import sun from '../../../inrees-landing/image/sun.svg';
import ticket from '../../../inrees-landing/image/ticket-top.png';
import rituel from '../../../inrees-landing/image/logo-rituel.png';
import or from '../../../inrees-landing/image/word-or.png';
import Btn from "../BtnStyle";


const Section1 = () => {


    return(
        <section className="top-ticket">
                <div className="rituel">
                    <ul>
                        <li>
                            <img src={sun} alt="" />
                        </li>
                        <li>
                            <img src={ticket} alt="" />
                        </li>   
                        <li>
                            <a href="https://ritueldelune.com/" target="_blank">
                                <p>En partenariat avec</p>
                                <img src={rituel} alt="Rituel de Lune" />
                            </a>
                        </li>                                                             
                    </ul>
                </div>    
                <h1><strong>Gagnez le</strong>
                  <img src={or} alt="Ticket d'or" />
                  <span>Cet automne, célébrez l'équinoxe et le <i>60<sup>ème</sup></i> numéro d'Inexploré,<br />participez au grand jeu-concours des abonnés !</span>
                </h1>
                <div className="button-to-participate">
                    <div className="scrollTop">
                        <Btn link='https://abonnements.inexplore.com/je-mabonne' customBtn="btn-table">
                        Je m'abonne pour participer
                        </Btn>
                    </div>
                </div>                        
            </section>
            
                  
    )
}

export default Section1