const Btn = ({link,customBtn,children}) => {
    

    return(
        <>
        <a href={link} className={`button ${customBtn}`} target="_blank">
           <span>{children}</span>
        </a>
        </>
    )
    
}

export default Btn