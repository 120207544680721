import React from "react";
import { HelmetProvider } from "react-helmet-async";
import Navigation from "./router/routes";
import Footer from "./components/layout/Footer";
import "./assets/scss/main.scss";
import "./inrees-landing/css/site/home.css";
import NavTop from "./components/layout/NavTop";

export default function App() {
  return (
    <HelmetProvider>
      <NavTop/>
      <Navigation/>
      <Footer />
    </HelmetProvider>
  );
}
