import React, { Fragment } from "react";
import gold from '../../../inrees-landing/image/gold.png';
import silver from '../../../inrees-landing/image/silver.png';
import bronze from '../../../inrees-landing/image/bronze.png';
import cover from '../../../inrees-landing/image/cover-tiny.png';
import Btn from "../BtnStyle";

const Section3 = () => {

    const listGold = [
        "1 défi quotidien lâcher-prise",
        "1 défi quotidien amour de soi",
        "1 carnet de lettres à brûler",
        "1 carnet de gratitude",
        "1 oracle sur le langage botanique",
        "1 bougie talisman (230ml)",

    ]
    const boxItem =  [
        {
            medal: gold,
            value: "gold",
            price: "Valeur 170 €", 
            lot: <Fragment>
                    <p>Une maxi box <a href="https://ritueldelune.com/" target="_blank" class="rituel de lune">Rituel de lune</a></p>
                        <ul>
                            {
                                listGold.map((value,index) => (
                                    <li key={index}>{value}</li>
                                    ))
                            }
                        </ul>
                        <p>+<br /><br />Une carte-cadeau Inexploré magazine</p>
                </Fragment>
        },
        {
            medal: silver,
            value: "silver",
            price: "Valeur 100 €", 
            lot: <Fragment>
                    <p>Une mini box <a href="https://ritueldelune.com/" target="_blank" class="rituel de lune">Rituel de lune</a></p>
                        <ul>
                            <li>1 carnet de gratitude ou de lettres à brûler</li>
                            <li>1 bougie talisman (230ml)</li>
                        </ul>
                    <p>+<br /><br />Une carte-cadeau Inexploré magazine</p>
                </Fragment>
        },
        {
            medal: bronze,
            value: "bronze",
            price: "Valeur 30 €", 
            lot: <Fragment>
                    <img class="cover-tiny" src={cover} alt="" />
                    <p>Une carte-cadeau<br />Inexploré magazine</p>
                </Fragment>
        },

    ]
    

    return(
        
        <section className="by-three take-chance">
            <div className="max-width">
            <h2>Abonnez-vous pour tenter de remporter...</h2>
                <ul className="list">
                    {
                        boxItem.map(({medal,price,value,lot},index) => {
                            return(
                               
                                <li key={index} className="item">
                                <img className="medal" src={medal} alt="" />
                                <h3 className={value}>{price}</h3>
                                <div className="lot">
                                    <div>
                                        {lot}
                                        
                                    </div>
                                </div>
                            </li>
                            )
                            
                        })
                    }
                </ul>
                <div className="button-to-participate">
                    <div className="scrollTop">
                        <Btn link='https://abonnements.inexplore.com/je-mabonne' customBtn="btn-table">
                        Je m'abonne
                        </Btn>
                    </div>
                </div>
            </div>
        </section>
                  
    )
}

export default Section3