import React from "react";
import { useNavigate } from "react-router-dom";
import SEOMixins from "../components/mixins/SEOMixins";

export default function MonCompte(props) {

  const navigate = useNavigate();

  return (
    <div className="main main-auth">
      <SEOMixins title="Mon compte" description="description de la Page" />
      <button
              type="button"
              className="btn-clear btn-clear-small-grey"
              onClick={() => navigate("/tiket-d-or")}
            >
              lien page mes abonnements
            </button>

    </div>
  );
}
