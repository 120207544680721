import React from "react";
import { Routes, Route } from "react-router-dom";
/* import pages */
import MonCompte from "../pages/MonCompte";
import MesAbonnements from "../pages/MesAbonnements";
import MonProfil from "../pages/MonProfil";
import TiketDor from "../components/layout/TiketDor";

export default function Navigation(props) {


  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<MonCompte/>}
      />
      <Route path="/tiket-d-or" element={<TiketDor />} />
      <Route path="/mesabonnements" element={<MesAbonnements />} />
      <Route path="/mon-profil" element={<MonProfil />} />
    </Routes>
  );
}
