import React from "react";
import covers from '../../../inrees-landing/image/cover.jpg';
import Btn from "../BtnStyle";

const Section7 = () => {

  
    return(
        <section class="revue">
            <div class="max-width">
                <div class="img">
                    <img src={covers} alt="" />
                </div>
                <div class="text">
                    <h2>Inexploré magazine</h2>
                    <p>
                    Une revue trimestrielle unique, à collectionner chaque solstice et équinoxe, au rythme des saisons et dédiée à vos
                     explorations existentielles…
                    </p>
                    <Btn link="https://www.inexplore.com/magazines" customBtn="btn-green-dark">
                     Découvrir le numéro en cours
                    </Btn> 
                </div>                        
            </div>
        </section>   
                  
    )
}

export default Section7