import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import * as footerActions from "../../store/actions/footerActions";
import InexploreWhiteLogo from "../../assets/images/svg/inexplore-logo-white.svg";
import InreesWhiteLogo from "../../assets/images/svg/logo-inrees-white.svg";
import { ReactComponent as Send } from "../../assets/images/svg/send.svg";
import ContactFooter from "../elements/contactFooter";


export default function Footer(props) {


  const myStyle = {
    display: "revert"
}
  const [footer, setFooter] = useState(null);

  const now = moment().format("YYYY");

  const dispatch = useDispatch();

  useEffect(() => {
    async function getFooter() {
      const response = await dispatch(footerActions.getFooter());
      setFooter(response.footer);
    }
    getFooter();
  }, []);


  if (footer !== null) {
    return (
      <footer>
        <div className="informations">
          <div className="inexplore-logo">
            <img
              src={InexploreWhiteLogo}
              className="logo"
              alt="logo Inexploré"
            ></img>
            <p>À la croisée des mondes</p>
          </div>
          <div className="socials">
            <div className="social-links">
              <p>{footer.reseaux.titre}</p>
              <ul>
                {footer.reseaux.items.map((item) => {
                  return (
                    <li key={item.titre}>
                      <a href={item.lien} target="_blank">
                        <img
                          src={item.picto}
                          className="icon"
                          alt={item.titre}
                        ></img>
                        {item.titre}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="newsletter">
              <p>Inscrivez-vous à notre newletter</p>
              <form className="form">
                <input type="email" />
                <button className="btn-footer-newsletter">
                  <Send style={myStyle}/>
                </button>
              </form>
            </div>
          </div>
          <div className="links">
            {footer.content.map((content) => {
              return (
                <ul>
                  <p>{content.titre}</p>
                  {content.items.map((item) => {
                    return (
                      <li key={item.titre}>
                        <a href={item.lien} target="_blank">
                          {item.titre}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              );
            })}
            <ul>
              <p>{footer.applications.titre}</p>
              {footer.applications.items.map((item) => {
                return (
                  <li key={item.titre}>
                    {item.titre}
                    <ul>
                      {item.plateforme.map((key , n) => {
                        return (
                          <li key={n + key.lien}>
                            <a href={key.lien} target="_blank">
                              <img src={key.picto} className="icon"></img>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="copyright">
            <p>
              Inexploré est édité par INREES - Copyright © 2007 - {now} - Tous
              droits réservés
            </p>
            <img src={InreesWhiteLogo} alt="Inrees"></img>
          </div>
        </div>
        <ContactFooter />
      </footer>
    );
  }
}
