import React from "react";
import { useNavigate } from "react-router-dom";
import SEOMixins from "../components/mixins/SEOMixins";
export default function MesAbonnements(props) {

  const navigate = useNavigate();

  /* States handler */


  return (
    <div className="main main-auth">
      <SEOMixins title="Mes abonnements" description="page mes abonnements" />
      <button
              type="button"
              className="btn-clear btn-clear-small-grey"
              onClick={() => navigate("/mon-profil")}
            >
              lien page mon profil
            </button>
    </div>
  );
}
