import React from "react";
import book from '../../../inrees-landing/image/picto/book.svg';
import computer from '../../../inrees-landing/image/picto/computer.svg';
import phone from '../../../inrees-landing/image/picto/web-phone.svg';
import Btn from "../BtnStyle";

const Section6 = () => {


    const boxList =  [
        {
            img: book,
            title: "Inexploré magazine", 
            text: "Une revue trimestrielle unique, à collectionner chaque solstice et équinoxe, au rythme des saisons et dédiée à vos explorations existentielles… En version papier dans cet abonnement.",
            price: "3,00 €",
            link: "https://abonnements.inexplore.com/je-mabonne?selectedAbos=[3]",

        },
        {
            img: computer,
            title: "Inexploré TV", 
            text: "Rejoignez dès maintenant la première plateforme française de vidéos dédiées à l ‘ouverture des consciences. De la science à la spriritualité, des milliers d 'heures de documentaires et de créations originales vous attendent.",
            price: "8,90 €",
            link: "https://abonnements.inexplore.com/je-mabonne?selectedAbos=[2]",

        },
        {
            img: phone,
            title: "Inexploré Digital", 
            text: "Des articles, podcasts et magazines numériques à découvrir depuis l 'application Inexploré. Le meilleur de l 'extraordinaire dans votre poche.",
            price: "2,90 €",
            link: "https://abonnements.inexplore.com/je-mabonne?selectedAbos=[1]",

        },
       

    ]

    return(
        <section className="formula" id="abo">
                    <div className="max-width">
                        <h2>Les formules Inexploré</h2>
                        <ul className="list">
                            {
                                boxList.map(({img,title,text, price,link},index) => {
                                    return(
                                         <li  key={index} className="item">
                                            <figure>
                                                <img src={img} alt="" />
                                            </figure>
                                            <h4>{title}</h4>
                                            <p>{text}</p>
                                            <div className="price">
                                                <span>À partir de</span>
                                                <p><strong>{price}</strong> /mois</p>
                                            </div>
                                            <Btn link={link} customBtn="btn-sec">
                                             Je m'abonne
                                            </Btn>  
                                        </li>	
                                    )
                                    
                                })
                            }                                                       
                        </ul>
                    </div>
                </section> 
                  
    )
}

export default Section6