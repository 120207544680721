import React from "react";
import logo from '../../inrees-landing/image/logo.svg';
import picto from '../../inrees-landing/image/picto/user.svg'


const NavTop = () => {

    return(
        <header>
            <div className="header max-width">
                <div className="header-top">
                    <div className="logo">
                        <a href="https://www.inexplore.com">
                            <img src={logo} alt="Accueil" />
                        </a>
                    </div>	
                    <div className="connect">
                        <a href="https://my.inexplore.com">
                            <span>
                                <img src={picto} alt="mon compte" />
                            </span>
                            <i>Se connecter</i>
                        </a>
                    </div>												
                </div>
            </div>
        </header>
    )
}

export default NavTop