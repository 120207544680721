import React from "react";
import candle from '../../../inrees-landing/image/candle.jpg';
import rite from '../../../inrees-landing/image/logo-rituel.png';

const Section4 = () => {

   

    return(
        <section className="partner">
        <div className="max-width">
            <div className="img">
                <img src={candle} alt="" />
            </div>
            <div className="text">
                <div>
                    <h2>En partenariat avec Rituel de Lune</h2>
                    <img src={rite} alt="Rituel de Lune" />
                </div>
                <p>
                    Célébrez la magie de l'équinoxe d 'automne avec les produits de la boutique aixoise <a href="https://ritueldelune.com/" target="_blank" class="underline">Rituel de Lune</a>.<br /><br />
                    Leurs bougies et créations originales vous transportent dans un voyage olfactif et énergétique où pierres naturelles
                    et senteurs fusionnent pour diffuser leurs bienfaits.
                </p>
            </div>
        </div>
    </section> 
                  
    )
}

export default Section4