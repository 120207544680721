import React from "react";
import { useNavigate } from "react-router-dom";
import SEOMixins from "../components/mixins/SEOMixins";

export default function MonProfil(props) {

  const navigate = useNavigate();


  return (
    <div className="main main-auth">
      <SEOMixins
        title="Mon profil"
        description="desription de la page"
      />
      <button
              type="button"
              className="btn-clear btn-clear-small-grey"
              onClick={() => navigate("/")}
            >
              lien page mon compte
            </button>
    </div>
  );
}
